<h3 mat-dialog-title>あなたはユーザー1です</h3>

<div mat-dialog-content>
    <div id="your_container">
        <!-- チャットの外側部分① -->
        <div id="bms_messages_container">
            <!-- タイムライン部分③ -->
            <div id="bms_messages">
                <ng-container *ngFor="let comment of socketService.comments">

                    <ng-container *ngIf="!comment.user">
                        <!--メッセージ１（左側）-->
                        <div class="bms_message bms_left">
                            <div class="bms_message_box">
                                <div class="bms_message_content">
                                    <div class="bms_message_text">
                                        {{comment.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bms_clear"></div><!-- 回り込みを解除（スタイルはcssで充てる） -->
                    </ng-container>

                    <ng-container *ngIf="comment.user">
                        <!--メッセージ２（右側）-->
                        <div class="bms_message bms_right">
                            <div class="bms_message_box">
                                <div class="bms_message_content">
                                    <div class="bms_message_text">
                                        {{comment.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bms_clear"></div><!-- 回り込みを解除（スタイルはcssで充てる） -->
                    </ng-container>
                </ng-container>

            </div>

            <!-- テキストボックス、送信ボタン④ -->
            <div id="bms_send">
                <textarea #send_message 
                    id="bms_send_message" 
                    (keydown.enter)="sendMessage(send_message.value); send_message.value='';">
                </textarea>
                <div #send_btn 
                    id="bms_send_btn" 
                    (click)="sendMessage(send_message.value); send_message.value='';">
                    送信
                </div>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>閉じる</button>
</div>