<h1 mat-dialog-title>主桁の編集</h1>

<div mat-dialog-content>

    <hot-table
        [settings]="hotSettings"
        [rowHeaderWidth]="100"
        [stretchH]="'last'"
        height="auto"
        licenseKey="non-commercial-and-evaluation">
    </hot-table>



</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>閉じる</button>
</div>