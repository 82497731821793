<h1 mat-dialog-title>横構の編集</h1>

<div mat-dialog-content>
    <hot-table
        [settings]="hotSettings"
        [rowHeaderWidth]="150"
        [stretchH]="'last'"
        height="auto"
        licenseKey="non-commercial-and-evaluation">
    </hot-table>

<!--
    <mat-list role="list">
        <mat-list-item role="listitem">W2
            <input matInput type="number" [(ngModel)]="model.cross.W2">
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.cross.W2" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.cross.W2}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">D3
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.cross.D3" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.cross.D3}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">tf2
            <mat-slider min="100" max="500" step="10" [(ngModel)]="model.cross.tf2" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.cross.tf2}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">tw2
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.cross.tw2" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.cross.tw2}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">s_edge
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.cross.s_edge" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.cross.s_edge}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">s_middle
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.cross.s_middle" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.cross.s_middle}}</label>
        </mat-list-item>
    </mat-list>
-->
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>閉じる</button>
</div>