<mat-toolbar>
    <button mat-icon-button  [matMenuTriggerFor]="menu" class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <label mat-menu-item >
            <span>
                ファイルを開く
                <input type="file" accept="application/json" (change)="open($event)" style="display: none" />
            </span>
        </label>
        <button mat-menu-item (click)="save()">ファイルを保存</button>
    </mat-menu>

    <span>合成桁-3Dモデルジェネレーター</span>
    <span class="example-spacer"></span>

    <button *ngIf="!isLoading" mat-icon-button class="example-icon favorite-icon" aria-label="download ifc file" (click)="download()">
        <mat-icon>cloud_download</mat-icon>
    </button>
    <div *ngIf="isLoading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>

    <button mat-icon-button class="example-icon" aria-label="Example icon-button with help_outline icon" (click)="goToLink()">
        <mat-icon>help_outline</mat-icon>
    </button>

    <button *ngIf="!isCalculating" mat-icon-button class="example-icon favorite-icon" aria-label="Calculate document" (click)="calculate()">
        <mat-icon>description</mat-icon>
    </button>
    <div *ngIf="isCalculating">
        <mat-spinner diameter="40"></mat-spinner>
    </div>

</mat-toolbar>