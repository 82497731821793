<h1 mat-dialog-title>端横桁の編集</h1>

<div mat-dialog-content>
    <hot-table
        [settings]="hotSettings"
        [rowHeaderWidth]="100"
        [stretchH]="'last'"
        height="auto"
        licenseKey="non-commercial-and-evaluation">
    </hot-table>
<!--
    <mat-list role="list">
        <mat-list-item role="listitem">D5
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.endbeam.D5" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.endbeam.D5}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">tf4
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.endbeam.tf4" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.endbeam.tf4}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">tw4
            <mat-slider min="100" max="500" step="10" [(ngModel)]="model.endbeam.tw4" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.endbeam.tw4}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">s_edge3
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.endbeam.s_edge3" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.endbeam.s_edge3}}</label>
        </mat-list-item>
        <mat-list-item role="listitem">s_middle3
            <mat-slider min="0.1" max="10" step="0.1" [(ngModel)]="model.endbeam.s_middle3" (change)="redraw()"></mat-slider>
            <label class="example-value-label">{{model.endbeam.s_middle3}}</label>
        </mat-list-item>
    </mat-list>
-->
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>閉じる</button>
</div>